import { all, put, takeEvery } from "redux-saga/effects";
import store from "../store";
import { setErrorStatus, setSuccessStatus } from "../status/action";
import {
  getProceduresByProcedureIds,
  getProceduresHistory,
  setProcedureHistory,
} from "../../Services/database";
import { setSelectedProcedure } from "./action";
import { locationRange } from "../../Utils/constants";
import { throwError } from "../../Services/error";

export const actionTypes = {
  GET_PROCEDURES: "GET_PROCEDURES",
  GET_PROCEDURES_HISTORY_DATA: "GET_PROCEDURES_HISTORY_DATA",
  ADD_SELECTED_PROCEDURE: "ADD_SELECTED_PROCEDURE",
  COMPLETE_PROCEDURE: "COMPLETE_PROCEDURE",
};

function* getProceduresWorker(action) {
  try {
    yield setProceduresLoading(true);
    const response = yield getProceduresByProcedureIds(
      action.payload.procedureIds
    );
    yield put({
      type: "SET_PROCEDURES_DATA",
      payload: { data: response },
    });
    yield setProceduresLoading(false);
  } catch (error) {
    yield setProceduresLoading(false);
    setErrorStatus(error);
  }
}

function* getProceduresHistoryDataWorker(action) {
  try {
    yield setProceduresLoading(true);
    const result = yield getProceduresHistory(
      action.payload.procedureId,
      action.payload.locationId,
      store.getState().profile.data.employeeId
    );
    yield put({
      type: "SET_PROCEDURES_HISTORY_DATA",
      payload: { data: result },
    });
    yield setProceduresLoading(false);
  } catch (error) {
    yield setProceduresLoading(false);

    setErrorStatus(error);
  }
}

function* addSelectedProcedureWorker(action) {
  try {
    yield setProceduresLoading(true);
    yield put({
      type: "SET_SELECTED_PROCEDURE",
      payload: { data: action.payload.data },
    });
    yield setProceduresLoading(false);
  } catch (error) {
    yield setProceduresLoading(false);
    setErrorStatus(error);
  }
}

function* completeProcedureWorker(action) {
  try {
    yield setProceduresLoading(true);
    const locations = store.getState().locations;
    const procedures = store.getState().procedures;
    if (locations.distance === null || locations.distance > locationRange) {
      yield setProceduresLoading(false);
      throw throwError("custom", "You are not in location");
    }

    const procedureHistoryData = {
      createdAt: +new Date(),
      employeeId: store.getState().profile.data.employeeId,
      latestUpdate: [],
      locationId: procedures.selectedProcedure.locationId,
      procedureId: procedures.selectedProcedure.procedureId,
      procedureType:
        procedures.data?.[procedures.selectedProcedure.procedureId]
          .procedureType,
    };

    yield setProcedureHistory(procedureHistoryData, action.payload.data);

    setSelectedProcedure(null);

    setSuccessStatus("Successfully finished");

    yield setProceduresLoading(false);
  } catch (error) {
    yield setProceduresLoading(false);
    console.error(error, "from setProcedureHistory");
    setErrorStatus(error);
  }
}

export default function* proceduresWatcher() {
  yield all([
    takeEvery("GET_PROCEDURES", getProceduresWorker),
    takeEvery("GET_PROCEDURES_HISTORY_DATA", getProceduresHistoryDataWorker),
    takeEvery("ADD_SELECTED_PROCEDURE", addSelectedProcedureWorker),
    takeEvery("COMPLETE_PROCEDURE", completeProcedureWorker),
  ]);
}

function* setProceduresLoading(bool) {
  yield put({
    type: "SET_PROCEDURES_LOADING",
    payload: {
      loading: bool,
    },
  });
}
