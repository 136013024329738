import React from "react";
import { InfoIcon, ProcedureIcon, ProfileIcon } from "../../Assets/assets";
import "./NavBar.css";

export default function NavBar(props) {
  const data = [
    {
      title: "Issue",
      route: "/complaints",
      icon: (
        <InfoIcon
          fill={`${
            window.location.pathname === "/complaints" ? "#0249D6" : "#404040"
          }`}
        />
      ),
      onClick: () => {
        props.navigate("/complaints");
      },
    },
    {
      title: "Audit",
      route: "/procedures",

      icon: (
        <ProcedureIcon
          fill={`${
            window.location.pathname === "/procedures" ? "#0249D6" : "#404040"
          }`}
        />
      ),
      onClick: () => {
        props.navigate("/procedures");
      },
    },
    {
      title: "Profile",
      route: "/profile",
      icon: (
        <ProfileIcon
          fill={`${
            window.location.pathname === "/profile" ? "#0249D6" : "#404040"
          }`}
        />
      ),
      onClick: () => {
        props.navigate("/profile");
      },
    },
  ];
  return (
    <div className="height-64px background-color-quaternary inherit-parent-width flex-justify-content-center "
    data-cy="nav-bar"
    >
      <div className=" inherit-parent-width flex-justify-content-space-evenly max-width-500px " >
      {data.map((data, index) => (
        <React.Fragment key={index}>
          <div
            className="display-flex flex-align-items-center flex-direction-column"
            data-cy={`navigate-to-${data.title}`}
            onClick={data.onClick}
          >
            <div className="display-flex flex-align-items-center padding-right-large padding-left-large padding-top-large padding-bottom-default">
              {data.icon}
              <div
                className={`${
                  window.location.pathname === data.route
                    ? "font-color-primary"
                    : ""
                } padding-left-default`}
              >
                {data.title}
              </div>
            </div>

            <div
              className={`${
                window.location.pathname === data.route
                  ? "background-color-primary"
                  : "background-color-quaternary"
              } height-2px width-40px border-radius-default`}
            />
          </div>
        </React.Fragment>
      ))}
      </div>
    </div>
  );
}
