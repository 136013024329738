const complaintsReducer = (
  state = {
    loading: false,
    data: null,
    filter: {
      category: [],
      dateRange: {
        from: null,
        to: null,
      },
      issueRaised: [],
      pctNo: [],
    },
  },
  action
) => {
  switch (action.type) {
    case "SET_COMPLAINTS_LOADING":
      return {
        ...state,
        loading: action.payload.loading,
      };

    case "SET_COMPLAINTS":
      return {
        ...state,
        data: action.payload.data,
      };

    case "UPDATE_COMPLAINTS_WITH_ASSETS":
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.complaintId]: action.payload.data,
        },
      };

    case "CLEAR_COMPLAINTS":
      return {
        ...state,
        data: null,
      };

    case "PUT_FILTER":
      return {
        ...state,
        filter: action.payload.data,
      };

    case "RESET":
      return {
        loading: false,
        data: null,
        filter: {
          category: [],
          dateRange: {
            from: null,
            to: null,
          },
          issueRaised: [],
          pctNo: [],
        },
      };

    default:
      return state;
  }
};
export default complaintsReducer;
