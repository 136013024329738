import React, { useState } from "react";
import NavBar from "../../Components/NavBar/NavBar";
import Header from "../../Components/Header/Header";
import RoundedProfilePicture from "../../Components/RoundedProfilePicture/RoundedProfilePicture";
import Modal from "../../Components/Modal/Modal";
import Button from "../../Components/Button/Button";
import { logout } from "../../Redux/authentication/actions";
import { connect } from "react-redux";

function Profile(props) {
  const [logoutModal, setLogoutModal] = useState(false);

  const data = [
    {
      title: "Name",
      value: props.profile?.data?.name,
    },
    {
      title: "Phone Number",
      value: props.profile?.data?.phoneNumber,
    },
  ];

  return (
    <div className="inherit-parent-height">
      <Header
        title="Profile"
        logoutOnClick={() => {
          setLogoutModal(true);
        }}
      />
      <div className="remaining-body-height_-128px flex-justify-content-space-between flex-direction-column">
        <div className="remaining-body-height inherit-parent-width display-flex flex-justify-content-center overflow-x-scroll hide-scrollbar">
          <div className=" inherit-parent-height inherit-parent-width max-width-767px padding-top-larger">
            <div className="flex-justify-content-center flex-align-items-center flex-direction-column padding-bottom-larger">
              <RoundedProfilePicture
                // editMode={true}
                size="large"
                // src={
                //   profileData.profilePicture
                //     ? URL.createObjectURL(profileData.profilePicture)
                //     : props.data.profilePictureUrl
                // }
                // setFile={(file) => {
                //   setFile(file);
                // }}
              />
            </div>
            <div className="padding-large">
              {data.map((data, index) => (
                <div className="padding-bottom-large" key={index}>
                  <div className="text-uppercase padding-bottom-default font-size-small">
                    {data.title}
                  </div>
                  <div>{data.value}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className=" position-absolute bottom-0 inherit-parent-width">
          <NavBar navigate={props.navigate} />
        </div>
      </div>
      <Modal
        show={logoutModal}
        canIgnore={true}
        onClose={() => {
          setLogoutModal(!logoutModal);
        }}
        width="inherit-parent-width"
        position=" absolute-center-self-horizontally bottom-0 "
        maxWidth="max-width-630px"
        background="false"
        boxShadow="false"
        borderRadius="false"
        height="height-fit-to-content"
      >
        <section className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-larger background-white">
          <div className=" font-family-RHD-medium font-size-medium">LOGOUT</div>
          <div className=" padding-top-default">
            Are you sure to logout from the application?
          </div>

          <section className="display-flex padding-top-larger ">
            <Button
              boxShadow={false}
              type="submit"
              variant="danger"
              text="Yes"
              className={"border-1px-e5e5e5 margin-right-small"}
              onClick={() => {
                props.logout();
              }}
              data-cy={"confirm-logout-button"}
            />
            <Button
              boxShadow={false}
              text="No"
              variant={"secondary"}
              onClick={() => {
                setLogoutModal(false);
              }}
              className={
                "font-color-secondary border-1px-e5e5e5 margin-left-small"
              }
              data-cy={"cancel-logout-button"}
            />
          </section>
        </section>
      </Modal>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    auth: state.auth,
    profile: state.profile,
  };
};

const mapDispatchToProps = function () {
  return {
    logout: () => logout(),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
