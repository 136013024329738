import store from "../store";
import { actionTypes } from "./saga";

/**
 * @module ProceduresSaga
 */

/**
 *
 * @param {array} procedureIds data has proceduresIds
 */
export function getProcedures(procedureIds) {
  store.dispatch({
    type: actionTypes.GET_PROCEDURES,
    payload: {
      procedureIds: procedureIds,
    },
  });
}

/**
 *
 * @param {object} payload data has proceduresHistoryData
 */
export function getProceduresHistory(procedureId, locationId) {
  store.dispatch({
    type: actionTypes.GET_PROCEDURES_HISTORY_DATA,
    payload: {
      procedureId: procedureId,
      locationId: locationId,
    },
  });
}

/**
 *
 * @param {object} payload data has selectedProcedure
 */
export function setSelectedProcedure(data) {
  store.dispatch({
    type: actionTypes.ADD_SELECTED_PROCEDURE,
    payload: {
      data: data,
    },
  });
}

/**
 *
 * @param {object} payload data has completeProcedure
 */
export function completeProcedure(data) {
  store.dispatch({
    type: actionTypes.COMPLETE_PROCEDURE,
    payload: {
      data: data,
    },
  });
}
