import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { dateAndTimeConverter } from "../../Utils/constants";
import { ArrowIcon, CloseIcon } from "../../Assets/assets";
import { useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Modal from "../../Components/Modal/Modal";
import { isValidArray } from "../../Services/validators";
import AudioPlayer from "../../Components/AudioPlayer/AudioPlayer";
import { fetchUpdateCompliantAssets } from "../../Redux/complaints/action";

function ViewUpdates(props) {
  const [selectedComplaint, setSelectedComplaint] = useState(null);
  const [modalData, setModalData] = useState({
    index: null,
    show: false,
  });

  const { complaintId } = useParams();

  useEffect(() => {
    if (props.complaints.data && props.complaints.data[complaintId]) {
      setSelectedComplaint(props.complaints.data[complaintId]);
    } else {
      props.navigate("/complaints");
    }
    // eslint-disable-next-line
  }, [props.complaints.data]);

  return (
    <>
      <div className="inherit-parent-height">
        <Header
          title="View updates"
          backOnClick={() => {
            props.navigate(`/complaints/${complaintId}`);
          }}
        />
        <div className="remaining-body-height inherit-parent-width display-flex flex-direction-column ">
          <div className=" inherit-parent-height inherit-parent-width max-width-500px flex-direction-column padding-large">
            {selectedComplaint?.updates?.map((data, index) => (
              <div key={index}>
                <UpdateCard
                  onCardClick={() => {
                    fetchUpdateCompliantAssets(complaintId);
                    setModalData({ index: index, show: true });
                  }}
                  data={data}
                />
              </div>
            ))}
          </div>

          <div className="flex-center-children-vertically margin-left-large margin-right-large padding-bottom-default">
            <hr
              style={{ width: "100%" }}
              className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
            />
            <span className=" margin-horizontal-default display-inline-block font-color-secondary font-size-small">
              OR
            </span>
            <hr
              style={{ width: "100%" }}
              className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
            />
          </div>

          <div className="display-flex flex-justify-content-center font-size-small padding-top-default padding-bottom-default">
            <div>Want to create a new update ? </div>
            <div
              data-cy="create-compliant-button"
              className="font-color-primary padding-left-default cursor-pointer"
              onClick={() => {
                props.navigate(`/complaints/${complaintId}?forceUpdate=true`);
              }}
            >
              Create
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={modalData.show}
        canIgnore={true}
        onClose={() => {
          setModalData({ index: null, show: false });
        }}
        position={"position-absolute bottom-0 left-0 right-0"}
        modalBackgroundClassName="position-absolute top-0 bottom-0 right-0 left-0 inherit-parent-height inherit-parent-width"
        borderRadius="false"
        boxShadow="false"
        maxWidth="inherit-parent-width"
        height="inherit-parent-hight"
        width="inherit-parent-width"
      >
        <div
          className={`${
            props.theme === "dark" ? "background-color-dark" : ""
          } padding-larger box-shadow-default font-family-gilroy-regular inherit-parent-height inherit-parent-width`}
          data-cy={"view-uploaded-files-modal"}
        >
          <div className="display-flex flex-justify-content-space-between padding-bottom-medium">
            <div style={{ width: "18px" }} />

            <div className="font-size-medium text-align-center font-family-RHD-medium">
              VIEW UPLOADED FILES
            </div>
            <div
              className="cursor-pointer"
              data-cy={`files-modal-close-button`}
              onClick={() => {
                setModalData({ index: null, show: false });
              }}
            >
              <CloseIcon
                height={18}
                width={18}
                color={props.theme === "dark" ? "white" : "#404040"}
              />
            </div>
          </div>

          {props.assetsLoading ? (
            <div
              style={{ minHeight: "164px" }}
              className="display-flex flex-justify-content-center flex-align-items-center"
            >
              <div
                className={
                  props.theme === "dark" ? "qr-loader-dark" : "qr-loader-light"
                }
              />
            </div>
          ) : (
            <>
              {isValidArray(
                selectedComplaint?.updates?.[modalData.index]?.assets?.images
              ) && (
                <>
                  <div className="font-size-small padding-bottom-default">
                    IMAGE
                  </div>

                  <div className=" inherit-parent-width display-flex ">
                    {selectedComplaint?.updates?.[
                      modalData.index
                    ]?.assets?.images?.map((url, index) => (
                      <div
                        key={index}
                        className={`height-width-56px margin-bottom-default ${
                          selectedComplaint?.updates?.[modalData.index]?.assets
                            ?.images?.length !==
                          index + 1
                            ? "margin-right-default"
                            : " "
                        } `}
                        onClick={() => {
                          props.setPreviewImage(
                            selectedComplaint?.updates?.[modalData.index]
                              ?.assets?.images?.[index]
                          );
                        }}
                      >
                        <img
                          className="border-radius-default inherit-parent-height inherit-parent-width"
                          data-cy={`proof-image-${props.selectedComplaintData?.documentId}`}
                          src={url}
                          alt="images"
                        />
                      </div>
                    ))}
                  </div>
                </>
              )}

              {selectedComplaint?.updates?.[modalData.index]?.remarks && (
                <div>
                  <div className="font-size-small padding-top-large padding-bottom-default">
                    REMARKS
                  </div>
                  <div>
                    {selectedComplaint?.updates?.[modalData.index]?.remarks}
                  </div>
                </div>
              )}

              {selectedComplaint?.updates?.[modalData.index]?.assets?.audio && (
                <>
                  <div className="font-size-small padding-top-large padding-bottom-default">
                    AUDIO{" "}
                    {!selectedComplaint?.updates?.[modalData.index]?.assets
                      ?.audio && <span>(No audio uploaded)</span>}
                  </div>
                  <AudioPlayer
                    className="max-width-500px"
                    src={
                      selectedComplaint?.updates?.[modalData.index]?.assets
                        ?.audio || null
                    }
                    dataCy={`proof-audio-${
                      selectedComplaint?.updates?.[modalData.index]?.assets
                        ?.audio
                        ? selectedComplaint?.documentId
                        : "null"
                    }`}
                  />
                </>
              )}
              {!isValidArray(
                selectedComplaint?.updates?.[modalData.index]?.assets?.images
              ) &&
                !selectedComplaint?.updates?.[modalData.index]?.remarks &&
                !selectedComplaint?.updates?.[modalData.index]?.assets
                  ?.audio && (
                  <div className="text-align-center padding-horizontal-larger">
                    Media is not required
                  </div>
                )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

const mapStateToProps = function (state) {
  return {
    complaints: state.complaints,
  };
};

const mapDispatchToProps = function () {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewUpdates);
const UpdateCard = (props) => {
  return (
    <div
      onClick={props.onCardClick}
      className="display-flex flex-justify-content-space-between flex-align-items-center padding-bottom-large"
    >
      <div>
        <div className="font-size-medium">{props.data.remarks}</div>
        <div className="font-size-small padding-top-small">
          {`${dateAndTimeConverter(
            props.data.updatedAt,
            "Time"
          )} , ${dateAndTimeConverter(props.data.updatedAt, "cardDate")}`}
        </div>
      </div>
      <div>
        <ArrowIcon />
      </div>
    </div>
  );
};
