import LogRocket from "logrocket";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ErrorBoundaryFallBack } from "./Assets/assets";
import SnackBar from "./Components/SnackBar/SnackBar";
import { forceRefreshAccessToken } from "./Redux/authentication/actions";
import {
  addUserDistance,
  getLocations,
  setUserLocation,
} from "./Redux/locations/action";
import { getProcedures } from "./Redux/procedures/action";
import Complaints from "./Screens/Complaints/Complaints";
import Login from "./Screens/Login/Login";
import Procedures from "./Screens/Procedures/Procedures";
import Profile from "./Screens/Profile/Profile";
import SelectedComplaint from "./Screens/SelectedComplaint/SelectedComplaint";
import SelectedProcedure from "./Screens/SelectedProcedure/SelectedProcedure";
import ViewUpdates from "./Screens/ViewUpdates/ViewUpdates";
import { useAuthSubscriber } from "./Services/authentication";
import { useComplaintsListener, useProfileListener } from "./Services/database";
import useLiveLocation from "./Services/getLocation";
import { isValidArray } from "./Services/validators";
import { logrocketInit } from "./Utils/constants";
// import UnsupportedDevice from "./Components/UnsupportedDevice/UnsupportedDevice";

function App(props) {
  const navigate = useNavigate();
  const [status, setStatus] = useState(null);
  const [isAuth, setIsAuth] = useState(false);
  const [locationAccess, setLocationAccess] = useState(true);

  const currentLocation = useLiveLocation(
    isAuth,
    setLocationAccess,
    locationAccess
  );

  useEffect(() => {
    if (
      props.locations?.currentLocation === null ||
      (currentLocation?.latitude &&
        props.locations?.currentLocation?.latitude !==
          currentLocation?.latitude) ||
      (currentLocation?.longitude &&
        props.locations?.currentLocation?.longitude !==
          currentLocation?.longitude)
    ) {
      if (currentLocation?.latitude && currentLocation?.longitude)
        props.setUserLocation({
          latitude: currentLocation.latitude,
          longitude: currentLocation.longitude,
        });
    }
    // eslint-disable-next-line
  }, [currentLocation]);

  useEffect(() => {
    addUserDistance();
    // eslint-disable-next-line
  }, [currentLocation, props.procedures.selectedProcedure]);

  useAuthSubscriber((isAuth) => {
    setIsAuth(isAuth);
  }, isAuth);

  useProfileListener({
    uid: props.auth.data.uid,
    phoneNumber: props.auth.data.phoneNumber,
    isAuth: isAuth,
  });

  useComplaintsListener({
    uid: props.auth?.data?.uid,
    locationIds: props?.profile?.data?.connected?.location,
    isAuth: isAuth,
  });

  useEffect(() => {
    if (isValidArray(props.profile?.data?.connected?.location) && isAuth) {
      props.getLocations(props?.profile?.data?.connected?.location);
    }
    if (isValidArray(props.profile?.data?.connected?.procedure) && isAuth) {
      props.getProcedures(props?.profile?.data?.connected?.procedure);
    }

    // eslint-disable-next-line
  }, [props.profile?.data?.connected, isAuth]);

  useEffect(() => {
    if (props.profile?.data?.accessSyncStatus === false && isAuth) {
      props.forceRefreshAccessToken(true);
    }
    // eslint-disable-next-line
  }, [props.profile?.data, isAuth]);

  //for snack bar messages
  useEffect(() => {
    if (props.status.message) {
      setStatus(props.status.message);
    } else {
      setStatus(null);
    }
  }, [props.status]);

  //for log rocket init
  useEffect(() => {
    if (
      process.env.NODE_ENV === "production" &&
      window.location.hostname !== "localhost"
    ) {
      const release =
        typeof process.env.REACT_APP_BUILD_NUM === "string"
          ? { release: process.env.REACT_APP_BUILD_NUM }
          : {};
      if (logrocketInit.key) {
        LogRocket.init(logrocketInit.key, release);
      }
    }
  }, []);

  return (
    // process.env.REACT_APP_STAGING === "production" &&
    // window.matchMedia("(display-mode: standalone)").matches) ||
    // process.env.REACT_APP_STAGING !== "production" ? (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              <Login navigate={navigate} />
            </PublicRoute>
          }
        />
        <Route
          path="/procedures"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              {!locationAccess && !window.Cypress ? (
                <LocationPermissionDeniedFallback />
              ) : (
                <Procedures navigate={navigate} />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/procedures/:procedureId"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              {!locationAccess && !window.Cypress ? (
                <LocationPermissionDeniedFallback />
              ) : (
                <SelectedProcedure navigate={navigate} />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/complaints"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              {!locationAccess && !window.Cypress ? (
                <LocationPermissionDeniedFallback />
              ) : (
                <Complaints navigate={navigate} />
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path="/complaints/:complaintId"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              {!locationAccess && !window.Cypress ? (
                <LocationPermissionDeniedFallback />
              ) : (
                <SelectedComplaint navigate={navigate} />
              )}
            </ProtectedRoute>
          }
        />

        <Route
          path="/viewUpdates/:complaintId"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              {!locationAccess && !window.Cypress ? (
                <LocationPermissionDeniedFallback />
              ) : (
                <ViewUpdates navigate={navigate} />
              )}
            </ProtectedRoute>
          }
        />

        <Route
          path="/profile"
          element={
            <ProtectedRoute
              uid={props.auth.data.uid}
              profileData={props.profile.data}
            >
              {!locationAccess && !window.Cypress ? (
                <LocationPermissionDeniedFallback />
              ) : (
                <Profile navigate={navigate} />
              )}
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<Navigate to="/complaints" replace />} />
      </Routes>
      <SnackBar
        message={status}
        status={props.status}
        type={props.status.code === null ? "success" : "error"}
      />
    </>
  );
  // : (
  //   <UnsupportedDevice appName="doctors" />
  // );
}

const mapStateToProps = function (state) {
  return {
    status: state.status,
    auth: state.auth,
    profile: state.profile,
    procedures: state.procedures,
    locations: state.locations,
  };
};

const mapDispatchToProps = function () {
  return {
    setUserLocation: (currentLocation) => setUserLocation(currentLocation),
    forceRefreshAccessToken: (status) => forceRefreshAccessToken(status),
    getLocations: (locationIds) => getLocations(locationIds),
    getProcedures: (procedureIds) => getProcedures(procedureIds),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

const ProtectedRoute = ({ uid, children, profileData }) => {
  if (uid === null && profileData === null) {
    return <Navigate to="/login" />;
  } else {
    return children;
  }
};

const PublicRoute = ({ uid, children, profileData }) => {
  if (uid === null && profileData === null) {
    return children;
  } else {
    return <Navigate to="/" />;
  }
};

const LocationPermissionDeniedFallback = () => {
  return (
    <div
      className=" inherit-parent-height padding-horizontal-large display-flex flex-direction-column flex-justify-content-center flex-align-items-center"
      data-cy="location-permission-denied-fallback"
    >
      <ErrorBoundaryFallBack />
      <div className=" padding-top-larger font-family-RHD-medium">
        Location access denied
      </div>
      <div className=" padding-top-medium text-align-center font-size-medium">
        You have blocked from tracking your location. To use this, change your
        location settings in browser.
      </div>
    </div>
  );
};
