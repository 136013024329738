import { createStore, applyMiddleware, compose } from "redux";
import { persistStore } from "redux-persist";
import createSagaMiddleware from 'redux-saga';
import LogRocket from "logrocket";
import {persistedReducer} from "./rootReducer"; 
import rootSaga from "./rootSaga";

// Create Redux Saga middleware
const sagaMiddleware = createSagaMiddleware();

// Compose with Redux DevTools extension
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Store configuration
const store = createStore(
  persistedReducer, // Make sure you define and import your root reducer
  composeEnhancer(
    applyMiddleware(
      sagaMiddleware,
      LogRocket.reduxMiddleware()
    )
  )
);

// Run the root saga
sagaMiddleware.run(rootSaga);

// Persist the store
export const persistor = persistStore(store);

export default store;